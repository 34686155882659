import React from 'react'
import { Trans } from '@lingui/react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import useTimeToRead from '../../hooks/useTimeToRead'
import DynamicSection from '../../content/DynamicSection'
import Date from '../../utils/Date'
import Image from '../../utils/Image'
import CustomText from '../../utils/CustomText'
import ShareButtonWithState from '../../utils/ShareButtonWithState'
import BlogPostPageSubHeader from './BlogPostPageSubHeader'

const BlogPostPage = ({
    name,
    title,
    headImage,
    body = [],
    description,
    publishedAt,
}) => {
    const timeToRead = useTimeToRead({ id: 'article' })

    return (
        <Page data-testid="BlogPostPage">
            <StickyHeader>
                <HeaderWithContent />
                <BlogPostPageSubHeader name={name} />
            </StickyHeader>
            <PageMain id="article">
                <div className="pt-11 pb-32 bg-gray-100">
                    <div className="container-xs relative">
                        <CustomText
                            as="h1"
                            customText={title}
                            className="font-medium text-4xl lg:text-6xl mb-5"
                            customTextClassName="font-accent text-5xl lg:text-7xl"
                        />
                        <div className="flex items-center justify-between space-x-8 mb-5 uppercase text-gray-600 font-medium text-xs">
                            <div className="flex items-center space-x-8">
                                <Date
                                    format="eee MMM dd"
                                    dateString={publishedAt}
                                />
                                <span>
                                    <Trans id="Reading time: {timeToRead} min" values={{ timeToRead }} />
                                </span>
                            </div>
                            <ShareButtonWithState />
                        </div>
                        <p className="font-medium text-lg lg:text-xl mb-6">
                            {description}
                        </p>
                    </div>
                </div>
                <div className="container-sm">
                    <div className="relative -top-28 w-full h-52 lg:h-[584px] -mb-14">
                        {headImage?.filename && (
                            <Image
                                fill
                                alt={headImage.alt}
                                src={headImage.filename}
                                className="object-cover"
                            />
                        )}
                    </div>
                </div>
                {body && body.map((content) => (
                    <DynamicSection
                        key={content._uid}
                        content={content}
                    />
                ))}
            </PageMain>
            <FooterWithContent />
        </Page>
    )
}

export default BlogPostPage
