import React from 'react'
import useShare from '../hooks/useShare'
import ShareModal from './ShareModal'
import Button, { VARIANT_BLUE_LINK } from './Button'

const ShareButtonWithState = ({
    text = 'Share',
    size,
    width,
    variant = VARIANT_BLUE_LINK,
    iconAfterName,
    iconBeforeName = 'share-alt',
}) => {
    const {
        onShare,
        onShareToX,
        onCopyLink,
        modalIsOpen,
        onCloseModal,
        onShareToFacebook,
    } = useShare()

    return (
        <>
            {modalIsOpen && (
                <ShareModal
                    onClose={onCloseModal}
                    onShareToX={onShareToX}
                    onCopyLink={onCopyLink}
                    onShareToFacebook={onShareToFacebook}
                />
            )}
            <Button
                size={size}
                width={width}
                variant={variant}
                onClick={onShare}
                iconAfterName={iconAfterName}
                iconBeforeName={iconBeforeName}
            >
                {text}
            </Button>
        </>
    )
}

export default ShareButtonWithState
