import React from 'react'
import { Trans } from '@lingui/react'
import Icon from './Icon'
import Modal from './Modal'
import Button, { VARIANT_BLUE_LINK } from './Button'

const ShareModal = ({
    onClose,
    onShareToX,
    onCopyLink,
    onShareToFacebook,
}) => (
    <Modal
        className="m-4 p-6 w-full lg:w-[24rem] min-h-[12rem] rounded-lg self-center mt-0 lg:mt-0"
        onClose={onClose}
    >
        <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-medium tracking-tight">
                <Trans id="Share with friends" />
            </h3>
            <Button
                onClick={onClose}
                size="small"
                iconAfterName="cross"
                variant={VARIANT_BLUE_LINK}
            >
                <Trans id="Close" />
            </Button>
        </div>

        <div className="flex flex-col">
            <button
                type="button"
                onClick={onShareToFacebook}
                className="flex items-center space-x-6 border-gray-100 border-b pb-5"
            >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.01333 0.151867C0.6314 0.3492 0.3082 0.676133 0.1388 1.03667L0 1.33187V16V30.6681L0.137333 30.9603C0.312533 31.3329 0.667067 31.6875 1.03973 31.8627C1.3296 31.9989 1.3942 32 9.2326 32H17.1333V25.8V19.6H15.0333H12.9333V17.2V14.8H15.0277H17.122L17.1517 12.35C17.1783 10.1566 17.1961 9.83893 17.3211 9.3168C17.5928 8.18253 18.0647 7.3074 18.8146 6.54707C19.8678 5.47913 21.1949 4.96113 23.0667 4.8874C23.9697 4.85187 26.6934 4.96327 26.9049 5.04447C26.9845 5.075 27 5.42727 27 7.2022V9.32353L25.25 9.34527C23.5885 9.36593 23.4827 9.37487 23.1588 9.52127C22.7379 9.71153 22.4539 9.9976 22.2534 10.4333C22.1059 10.7539 22.0992 10.8436 22.0782 12.7833L22.0564 14.8H24.4368H26.8172L26.7711 15.2167C26.7051 15.8133 26.2761 19.1253 26.2315 19.3833L26.1939 19.6H24.1303H22.0667V25.8V32H26.3674C30.5709 32 30.6747 31.9969 30.9603 31.8627C31.3329 31.6875 31.6875 31.3329 31.8627 30.9603L32 30.6681V16V1.33187L31.8627 1.03973C31.6875 0.667067 31.3329 0.312533 30.9603 0.137333L30.6681 0L15.9841 0.00186667L1.3 0.00373333L1.01333 0.151867Z" fill="#415698" />
                </svg>
                <span>Facebook</span>
            </button>
            <button
                type="button"
                onClick={onShareToX}
                className="flex items-center space-x-6 border-gray-100 border-b py-5"
            >
                <div className="bg-black p-1 rounded-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1200 1227" fill="none">
                        <path fill="#fff" d="M714.163 519.284 1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026ZM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026Z" />
                    </svg>
                </div>
                <span>X</span>
            </button>
            <button
                type="button"
                onClick={onCopyLink}
                className="flex items-center space-x-6 pt-5"
            >
                <div className="p-1">
                    <Icon name="link" />
                </div>
                <span><Trans id="Copy link" /></span>
            </button>
        </div>
    </Modal>
)

export default ShareModal
