import { useState } from 'react'
import { useLingui } from '@lingui/react'
import { isMobileBrowser } from '../../util/index'
import useNotifications from './useNotifications'

const OPTIONS = 'toolbar=0,status=0,resizable=1,width=626,height=436'

const useShare = () => {
    const { i18n } = useLingui()
    const { dispatchSuccess } = useNotifications()
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const { href } = typeof window !== 'undefined' && window.location
    const { title } = typeof document !== 'undefined' && document
    const appId = process.env.NEXT_PUBLIC_FB_APP_ID
    const encodedHref = encodeURIComponent(href)

    const handleShare = async () => {
        if (isMobileBrowser() && navigator.share) {
            const shareData = {
                title,
                url: href
            }
            await navigator.share(shareData)
        } else {
            setModalIsOpen(true)
        }
    }
    const handleCloseModal = () => {
        setModalIsOpen(false)
    }
    const handleShareToFacebook = () => {
        const url = `https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&href=${encodedHref}`
        window.open(url, 'sharer', OPTIONS)
        handleCloseModal()
    }
    const handleShareToX = () => {
        const encodedTitle = encodeURIComponent(title)
        const url = `https://x.com/intent/tweet?text=${encodedTitle}&url=${encodedHref}`
        window.open(url, 'sharer', OPTIONS)
        handleCloseModal()
    }
    const handleCopyLink = () => {
        navigator.clipboard.writeText(href)
        dispatchSuccess({ message: i18n._('Link copied to clipboard') })
        handleCloseModal()
    }

    return {
        modalIsOpen,
        onShare: handleShare,
        onShareToX: handleShareToX,
        onCopyLink: handleCopyLink,
        onCloseModal: handleCloseModal,
        onShareToFacebook: handleShareToFacebook,
    }
}

export default useShare
