import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { useSearchParams } from 'next/navigation'
import * as constants from '@connections/constants'
import useTimeout from '../../hooks/useTimeout'
import usePageProps from '../../hooks/usePageProps'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import QueryParamProvider from '../../providers/QueryParamProvider'
import SearchPage from './SearchPage'
import useSearchPageQuery from './useSearchPageQuery'

const {
    PAGE_ENTITY: PAGE,
    SHOP_ENTITY: SHOP,
    TOUR_ENTITY: TOUR,
    BREAK_ENTITY: BREAK,
    HOTEL_ENTITY: HOTEL,
    EXPERT_ENTITY: EXPERT,
    ACTIVITY_ENTITY: ACTIVITY,
    BLOG_POST_ENTITY: BLOG_POST,
    DESTINATION_ENTITY: DESTINATION,
} = constants

function getQuery(searchParams) {
    const query = searchParams.get('query')

    if (query?.toLowerCase() === 'usa') {
        return 'United States'
    }

    return query
}

const SearchPageWithState = ({
    data,
    resolveRelations,
}) => {
    const page = usePageProps()
    const searchParams = useSearchParams()
    const [nothingWasSearched, setNothingWasSearched] = useState(false)
    const { content } = useStoryblokBridge(data.pageStory, resolveRelations)

    const query = getQuery(searchParams)
    const { contactJotformId } = page.data.settingsStory

    const tours = useSearchPageQuery(TOUR, { query })
    const shops = useSearchPageQuery(SHOP, { query })
    const pages = useSearchPageQuery(PAGE, { query })
    const breaks = useSearchPageQuery(BREAK, { query })
    const hotels = useSearchPageQuery(HOTEL, { query })
    const experts = useSearchPageQuery(EXPERT, { query })
    const activities = useSearchPageQuery(ACTIVITY, { query })
    const blogPosts = useSearchPageQuery(BLOG_POST, { query })
    const destinations = useSearchPageQuery(DESTINATION, { query })

    useTimeout(() => {
        if (query === '') {
            setNothingWasSearched(true)
        }
    }, 1000)

    const allState = [
        breaks,
        tours,
        activities,
        blogPosts,
        experts,
        shops,
        destinations,
        hotels,
        pages
    ]
    const isFetchingAll = (
        allState.some(({ isFetching }) => isFetching)
        || query === ''
    ) && !nothingWasSearched
    const hasNoResults = allState.every(({ total }) => total === 0)

    return (
        <SbEditable content={content}>
            <SearchPage
                query={query}
                shops={shops}
                pages={pages}
                tours={tours}
                hotels={hotels}
                breaks={breaks}
                experts={experts}
                blogPosts={blogPosts}
                activities={activities}
                hasNoResults={hasNoResults}
                destinations={destinations}
                isFetchingAll={isFetchingAll}
                contactJotformId={contactJotformId}
                nothingWasSearched={nothingWasSearched}
                {...content}
            />
        </SbEditable>
    )
}

export default (props) => (
    <QueryParamProvider>
        <SearchPageWithState {...props} />
    </QueryParamProvider>
)
